@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  display: flex;
  flex-flow: column;
  position: relative;
  will-change: max-height;
  margin-bottom: 60px;

  .row {
    align-items: flex-start;
    position: relative;
  }
}

.about,
.faq,
.tips {
  background: colors.$white;
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  margin-top: 15px;
  padding: 10px;
  word-break: break-word;
  z-index: 1;

  @media screen and (min-width: vars.$medium) {
    margin-top: 30px;
    padding: 30px;
  }
}

.aboutHeading,
.faqHeading,
.tipsHeading {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  b {
    display: block;

    @media screen and (min-width: vars.$medium) {
      display: inline-block;
    }
  }
}

.aboutHeading b {
  display: inline-block;
}

.aboutIcon,
.faqIcon,
.tipsIcon {
  align-items: center;
  background: colors.$background;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  height: 54px;
  justify-content: center;
  margin-right: 10px;
  padding: 5px;
  width: 54px;
}

.aboutIcon {
  color: colors.$primary-color;
}

.aboutContent,
.faqContent,
.tipsContent {
  padding-top: 15px;

  b {
    font-weight: normal;
    font-stretch: normal;
  }

  p {
    margin-bottom: 15px;
    text-align: left !important;
  }

  @media screen and (min-width: vars.$medium) {
    padding-top: 15px;
  }

  h3,
  h4 {
    font-size: 1em;
    font-weight: 400;
    font-weight: bold;
    line-height: 1.375rem;
    font-stretch: condensed;
  }

  ul,
  ol {
    margin-left: 20px;
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 10px;
    list-style: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 6.5px;
      width: 10px;
      height: 10px;
      border-radius: 50px;
      background-color: colors.$grey;
    }

    b {
      font-stretch: condensed;
      font-weight: bold;
    }
  }
}

.about {
  @media screen and (min-width: vars.$extra-large) {
    order: 1;
  }
}

.tips {
  @media screen and (min-width: vars.$extra-large) {
    order: 3;
  }
}

.tipsIcon {
  color: colors.$primary-color;
  font-size: 1.5rem;
}

.faq {
  background: colors.$primary-color;
  position: sticky;
  top: calc(150px + vars.$gutter);

  @media screen and (min-width: vars.$extra-large) {
    order: 2;
  }
}

.faqHeading,
.faqContent {
  color: colors.$white;
}

.faqContent {
  h3 {
    font-style: italic;
  }

  a {
    color: colors.$white;
    text-decoration: underline;
  }
}

.faqIcon {
  color: colors.$primary-color;
  font-size: 1.5rem;
}

.showMoreBtnGradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), colors.$background);
  height: 200px;
  width: 100%;
}

.showMoreBtnContainer {
  align-items: center;
  display: flex;
  flex: 0 0 100%;
  flex-flow: column;
  margin-top: -200px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;

  &.hideShowMoreContainer {
    margin-bottom: -28px;
    margin-top: -200px;
    .showMoreBtnGradient {
      height: 0;
    }
  }
}

.hideGradient {
  background: none;
}

.showMoreBtn {
  align-items: center;
  border: 1px solid colors.$primary-color;
  border-radius: 4px;
  color: colors.$primary-color;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  outline: none;
  position: relative;
  height: 45px;
  width: calc(100% - 20px);
  background-color: colors.$background;
  margin-top: 30px;
  margin-bottom: 60px;

  @media screen and (min-width: vars.$large) {
    width: 466px;
  }
}

.showMoreBtnChevronIcon {
  margin-left: 10px;
}

.showMoreBtnChevronIconRotated {
  transform: rotate(180deg);
}

.isActive {
  margin-top: -250px;
  margin-top: 0;

  .showMoreBtnGradient {
    height: 0;
  }
}
