.pageDescription {
  border-top: 1px solid var(--primary-color-light);
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #fff;
  contain: content;

  p {
    margin-bottom: 40px;
  }

  h3 {
    margin-bottom: 10px;
  }
}
