@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  font-size: 0.625rem;
  height: 20px;
  justify-content: center;
  outline: 0;
  width: 20px;
}
