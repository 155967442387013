@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.parent {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0 !important;
  position: relative;

  > h3 {
    display: none;
  }

  > div > div {
    height: 100%;
    overflow: initial;
    padding-bottom: 0;

    article:first-child {
      margin-left: 0;
    }
  }
}

.blogGrid {
  margin: 15px 0px;
  overflow: scroll;
  padding: 0;
  position: relative;

  @media screen and (min-width: vars.$large) {
    overflow: initial;
  }

  > div > div {
    margin: 15px 0 15px 10px;
    @media screen and (min-width: vars.$large) {
      margin: 15px 0;
    }

    > div {

      &:hover {
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
  }
}

.blogGridMobileRow {
  @media screen and (max-width: vars.$large) {
    display: flex;
  }
}

.showMoreBtn {
  display: none;

  &Mobile {
    display: initial;
    border: none;
    color: colors.$primary-color;
    padding: 30px;
    align-self: center;
    background-color: initial;
    
    .iconWrapper {
      height: 3rem;
      width: 3rem;
      border-radius: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid colors.$primary-color;
      margin: 1rem 0;

      svg {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: vars.$large) {
    align-items: center;
    background: colors.$background;
    border: none;
    bottom: -28px;
    color: colors.$primary-color;
    cursor: pointer;
    display: flex;
    flex: 0 0 28px;
    height: 28px;
    justify-content: center;
    left: 50%;
    outline: none;
    position: absolute;
    transform: translateX(-50%);
    width: 200px;

    &Mobile {
      display: none;
    }

    &:before {
      background-color: colors.$background;
      border-bottom-left-radius: 6px;
      content: '';
      height: 28px;
      left: -10px;
      position: absolute;
      top: 0;
      transform: skew(15deg);
      width: 15px;
    }

    &:after {
      background-color: colors.$background;
      border-bottom-right-radius: 6px;
      content: '';
      height: 28px;
      position: absolute;
      right: -10px;
      top: 0;
      transform: skew(-15deg);
      width: 15px;
    }
  }

  svg {
    margin-left: 10px;
  }
}

.containerItem1 {
  grid-area: a;
}

.containerItem2 {
  grid-area: b;
}

.containerItem3 {
  grid-area: c;
}

.containerItem4 {
  grid-area: d;
}

.containerItem5 {
  grid-area: e;
}

.containerItem6 {
  grid-area: f;
}

.containerItem7 {
  grid-area: g;
}

.containerItem8 {
  grid-area: h;
}

.containerItem9 {
  grid-area: i;
}

.containerItem10 {
  grid-area: j;
}

.containerItem11 {
  grid-area: k;
}

.containerItem12 {
  grid-area: l;
}

.container4 {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d'
    'a b c d'
    'a b c d';
  grid-template-columns: repeat(4, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
  }
}

.container5,
.container6 {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c c'
    'a b c c'
    'a b c c';
  grid-template-columns: repeat(4, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
  }
}

.container7,
.container8,
.container9 {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d g g f e'
    'a b c d g g f e'
    'a b c d g g f e';
  grid-template-columns: repeat(8, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-areas:
      'a b c d'
      'a b c d'
      'g g f e'
      'g g f e';
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
    grid-template-rows: repeat(4, minmax(175px, 175px));
  }
}

.container10 {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d f e i i g j j h'
    'a b c d f e i i g j j h'
    'a b c d f e i i g j j h';
  grid-template-columns: repeat(12, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-areas:
      'a b c d'
      'a b c d'
      'a b c f'
      'i i e f'
      'i i e f'
      'g h j j'
      'g h j j';
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
    grid-template-rows: repeat(7, minmax(175px, 175px));
  }
}

.container4With1Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d'
    'a b c d'
    'a e c d';
  grid-template-columns: repeat(4, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
  }
}

.container5With1Special,
.container6With1Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c c'
    'a b c c'
    'a d c c';
  grid-template-columns: repeat(4, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
  }
}

.container7With1Special,
.container8With1Special,
.container9With1Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d e f g g'
    'a b c d e f g g'
    'a b c d e f h ...';
  grid-template-columns: repeat(8, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-areas:
      'a b c d'
      'a b c d'
      'a h c e'
      'g g f e'
      'g g f e';
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
    grid-template-rows: repeat(5, minmax(175px, 175px));
  }
}

.container10With1Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d i i j j'
    'a b c d i i j j'
    'a k c e g h f ...';
  grid-template-columns: repeat(8, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-areas:
      'a b c d'
      'a b c d'
      'a k c f'
      'i i e f'
      'i i e f'
      'g h j j'
      'g h j j';
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
    grid-template-rows: repeat(7, minmax(175px, 175px));
  }
}

.container4With2Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d'
    'a b c d'
    'a e f d';
  grid-template-columns: repeat(4, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
  }
}

.container5With2Special,
.container6With2Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c c'
    'a b c c'
    'd e c c';
  grid-template-columns: repeat(4, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
  }
}

.container7With2Special,
.container8With2Special,
.container9With2Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b f e g g'
    'a b d e g g'
    'a c d e h i';
  grid-template-columns: repeat(6, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-areas:
      'a b c d'
      'a b c d'
      'a h i e'
      'g g f e'
      'g g f e';
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
    grid-template-rows: repeat(5, minmax(175px, 175px));
  }
}

.container10With2Special {
  display: grid;
  grid-gap: 15px;
  grid-template-areas:
    'a b c d i i g ... ...'
    'a b c d i i g j j'
    'a f k h e l g j j';
  grid-template-columns: repeat(9, minmax(260px, 260px));
  grid-template-rows: repeat(3, minmax(175px, 175px));
  @media screen and (min-width: vars.$large) {
    grid-template-areas:
      'a b c d'
      'a b c d'
      'a k l f'
      'i i e f'
      'i i e f'
      'g h j j'
      'g h j j';
    grid-template-columns: repeat(4, minmax(calc(25% - 15px), 25%));
    grid-template-rows: repeat(7, minmax(175px, 175px));
  }
}

.article {
  background-color: colors.$white;
  border-radius: 4px;
  color: colors.$default-text;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  &:after {
    background-image: linear-gradient(rgba(255, 255, 255, 0), colors.$white);
    bottom: 35px;
    content: '';
    cursor: pointer;
    height: 75px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &Link {
    text-decoration: none;
  }
}

.small {
  &:after {
    display: none;
  }
}

.bubble {
  background-color: colors.$secondary-color;
  border-radius: 4px;
  color: colors.$white;
  font-size: 0.875rem;
  font-stretch: condensed;
  font-weight: bold;
  height: 25px;
  left: 15px;
  line-height: 25px;
  min-width: 35px;
  padding: 0 5px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 15px;
  vertical-align: middle;
}

.category {
  color: colors.$primary-color;
  margin: 10px 0;
  visibility: hidden;
}

.title {
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 600;
}

.contentContainer {
  padding: 0 15px;
}

.sliderCard img {
  height: auto;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.article .imageContainer {
  border-radius: 4px 4px 0px 0px;
  height: 175px;
  max-height: 175px;
  overflow: hidden;
  width: 100%;

  img {
    min-height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.bottom {
  align-items: center;
  background-color: colors.$white;
  bottom: 0;
  display: flex;
  height: 35px;
  justify-content: space-between;
  left: 0;
  margin-top: 35px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
}

.like {
  background-color: transparent;
  border: 1px solid colors.$primary-color;
  color: colors.$primary-color;
  margin-left: 15px;

  &.liked,
  &:hover {
    background-color: colors.$primary-color;
    color: colors.$white;
  }
}

.timeToRead {
  align-items: center;
  color: #9b9b9b;
  display: flex;
  font-size: 0.75rem;
  margin-right: 15px;

  svg {
    display: block;
    margin-right: 5px;
  }
}

.quote {
  align-items: center;
  background-color: colors.$primary-color;
  color: colors.$white;
  cursor: auto;
  display: flex;
  font-style: italic;
  height: 100%;
  padding: 15px;
}

.navBackground {
  background-color: hsla(0, 0%, 100%, 0.5);
  @media screen and (max-width: vars.$large) {
    display: block;
    opacity: 1;
  }
}

.sliderCard {
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  position: relative;

  &:after {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &Link {
    text-decoration: none;
  }
}

.sliderCard .slide {
  height: 100%;
  position: relative;
}

.sliderCard .sliderImageContainer {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.sliderCard .bottom {
  background-color: initial;

  .like {
    border-color: colors.$white;
    color: colors.$white;

    &.liked,
    &:hover {
      background-color: colors.$white;
      color: #000;
    }
  }
}

.slideContent {
  bottom: 45px;
  color: colors.$white;
  left: 15px;
  position: absolute;
  z-index: 1;
}
